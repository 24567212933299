import React from "react"
import "./global.css"

const Layout = ({ children }) => {
  return (
    <div style={{
        backgroundColor: "var(--bg)",
        color: "var(--textNormal)",
        transition: "color 0.2s ease-out, background 0.2s ease-out",
        minHeight: "100vh",
      }}
    >
      <div className="main-content">
        <main>{children}</main>
      </div>
    </div>
  )
}

export default Layout
